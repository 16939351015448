import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { modalContent, planType } from '../../shared/subscription-constants'
/**
 * A hook to handle user next step after select plan
 * @returns {{ processSelection: function }}
 */
export const useSubscriptionModalSelection = () => {
  const location = useLocation()
  const history = useHistory()
  const flags = useFeatureFlags(['discount_basic', 'discount_standard', 'discount_annual'])
  const showBasicDiscount = flags.discount_basic
  const showStandardDiscount = flags.discount_standard
  const showAnnualDiscount = flags.discount_annual
  /**
   * Direct to payment if user doesn't has valid card
   * if has valid will subscribe select plan
   * if in show page and select monthly plan, will direct to voucher page
   * @param plan - planType
   */
  const processSelection = (plan) => {
    const isInMyAccount = /^\/my-account\//g.test(location.pathname)
    const selectedSubscriptionInOfferPeriod =
      (plan === planType.BASIC && showBasicDiscount) ||
      (plan === planType.STANDARD && showStandardDiscount) ||
      (plan === planType.ANNUAL && showAnnualDiscount)
    const content = isInMyAccount || selectedSubscriptionInOfferPeriod ? modalContent.payment : modalContent.voucher
    const updatedContent = { ...qs.parse(location.search) }
    delete updatedContent.currentSubscription

    history.push({
      ...location,
      search: qs.stringify({
        ...updatedContent,
        plan,
        content
      })
    })
  }

  return {
    processSelection
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './subscription-selection-modal-new.styles'
import { useSubscriptionModalSelection } from '../../hooks/use-subscription-modal-selection'
import { useSelectPlan } from '../../../signup/hooks/use-select-plan'
import SubscriptionPricesView from '../../../signup/components/subscription-prices-view/subscription-prices-view'
import SubscriptionContentsView from '../../../signup/components/subscription-contents-view/subscription-contents-view'
import SubscriptionCardContent from '../../../signup/components/subscription-card-content/subscription-card-content'
import { planDetailsForAvod } from '../../../shared/subscription-constants'

/**
 * Component display plan options in my account add subscription modal
 */

const SubscriptionSelectionModalNew = props => {
  const styles = useStyles()
  const { currentSubscription, oneWayChangeDirection, hasRecurringVouchers } = props

  const { processSelection } = useSubscriptionModalSelection()
  const { activeSubscription, updateActiveSubscription } = useSelectPlan(currentSubscription)

  return (
    <div className={styles.standardScreenContainer}>
      <div className={styles.tableRowContainer}>
        <div className={styles.tableRowTitleFrame}>
          <div className={styles.tableRowTitle}>{planDetailsForAvod.componentTitle}</div>
          <div className={styles.paymentMethodCard}>
            <SubscriptionCardContent />
          </div>
        </div>

        <div className={styles.tableRowContent}>
          <SubscriptionPricesView
            activeSubscription={activeSubscription}
            updateActiveSubscription={updateActiveSubscription}
            currentSubscription={currentSubscription}
          />
        </div>
      </div>

      <SubscriptionContentsView
        activeSubscription={activeSubscription}
        updateActiveSubscription={updateActiveSubscription}
        selectToNext={() => processSelection(activeSubscription.toUpperCase())}
        currentSubscription={currentSubscription}
        oneWayChangeDirection={oneWayChangeDirection}
        hasRecurringVouchers={hasRecurringVouchers}
        addSubscriptionFromSignup={false}
      />
    </div>
  )
}

SubscriptionSelectionModalNew.propTypes = {
  currentSubscription: PropTypes.string.isRequired,
  oneWayChangeDirection: PropTypes.string,
  hasRecurringVouchers: PropTypes.string
}

SubscriptionSelectionModalNew.defaultProps = {
  currentSubscription: '',
  hasRecurringVouchers: null
}
export default SubscriptionSelectionModalNew

import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import EvIframe from '../../../../../containers/shared/evergent-iframe'
import styles from './subscription-payment-view.css'
import PlanDetails from '../../../../../modules/signup/components/plan-details'
import { useStyles } from '../../../../../modules/signup/components/voucher-messages/voucher-messages.styles'
import SUBSCRIPTION_PAYMENT_VIEW_DETAILS_FRAGMENT from '../../../../../../graphql/fragments/subscription-payment-view-details.gql'
import { ADD_VOUCHER_SUCCESSFUL_MESSAGES } from '../../../../../constants'
import { CreditCardType } from '../../../../../modules/shared/credit-card-picker/item/credit-card-item'
import { useCreditCardPickerSubmit } from './hooks/use-credit-card-picker-submit'
import RadioDotChecked from '../../../../icons/radio-dot-checked'
import RadioDot from '../../../../icons/radio-dot'
import { LoadingComponent } from '../../../../loading'
import CreditCardPicker from '../../../../../modules/shared/credit-card-picker/credit-card-picker'
import { usePlanChangeFlag } from '../../../../../hooks/usePlanChange'
import { usePriceChangeAlertFlag } from '../../../../../hooks/usePriceChangeAlert'
import { usePlanDetails } from '../../../../../modules/shared/hooks/use-plan-details'
import PaymentPriceChangeAlert from '../../../../../modules/shared/payment-price-change-alert/payment-price-change-alert'

function SubscriptionPaymentView({
  iframe,
  onIframeLoad,
  onCancel,
  continueCta,
  onClickContinueCta,
  subscriptionId,
  details,
  isVoucherApplied,
  validCreditCards,
  onCompletePayment,
  subscriptionLoading,
  isSelectedCardPicker,
  setIsSelectedCardPicker,
  selectedCardPaymentId,
  setSelectedCardPaymentId,
  setSubscriptionLoading
}) {
  const voucherStyles = useStyles()
  const getPlanDetails = usePlanChangeFlag()
  const showPriceChangeAlert = usePriceChangeAlertFlag()
  const { getPlanDetailById } = usePlanDetails()
  // get plan price and add plan header to 1st line
  const plan = getPlanDetailById(subscriptionId)
  const newDetails = [
    {
      name: plan.staticDetail.name,
      value: plan.staticDetail.value
    },
    ...details
  ]

  const {
    validationLoading,
    clearInput,
    ...passwordValidation
  } = useCreditCardPickerSubmit(selectedCardPaymentId, onCompletePayment, setSubscriptionLoading)

  const creditCardsPickerHandler = (paymentId) => {
    setSelectedCardPaymentId(paymentId)
    clearInput()
  }

  const DotIndicator = ({ isSelected }) => {
    const radioStyles = useStyles()
    return isSelected ? <RadioDotChecked className={radioStyles.icon} /> : <RadioDot className={radioStyles.icon} />
  }

  DotIndicator.propTypes = {
    isSelected: PropTypes.bool.isRequired
  }

  const priceChangeAlert = () => {
    const isAnnuallyPlan = newDetails[0].name === getPlanDetails?.annual.staticDetail.name
    const isStandardPlan = newDetails[0].name === getPlanDetails?.standard.staticDetail.name

    if (isStandardPlan || isAnnuallyPlan) {
      return <PaymentPriceChangeAlert isStandardPlan={isStandardPlan} isAnnuallyPlan={isAnnuallyPlan} />
    }

    return null
  }

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Payment Details - NEON</title>
      </Helmet>
      <div className={styles.content}>
        <h1 className={styles.header}>
          Add payment details
        </h1>

        <p className={styles.subHeader}>
          Please provide your credit card details below to complete your account set up. You can cancel any time.
        </p>

        <PlanDetails detail={newDetails} />

        { showPriceChangeAlert && priceChangeAlert() }

        {
          isVoucherApplied && (
            <>
              <div className={voucherStyles.copyMessage}>
                {ADD_VOUCHER_SUCCESSFUL_MESSAGES.successMsg}
              </div>
              <div className={voucherStyles.copy}>
                {ADD_VOUCHER_SUCCESSFUL_MESSAGES.confirmMsg}
              </div>
            </>
          )
        }

        {
          validCreditCards.length > 0
            ? (
              <>
                {(validationLoading || subscriptionLoading)
                  ? (
                    <div className={voucherStyles.border}>
                      <LoadingComponent pastDelay />
                    </div>
                  )
                  : (
                    <>
                      <div className={voucherStyles.border}>
                        <div
                          className={voucherStyles.selector}
                          onClick={() => setIsSelectedCardPicker(true)}
                        >
                          <DotIndicator isSelected={isSelectedCardPicker} />
                          Use an existing credit card
                        </div>
                        <div className={voucherStyles.divider} />
                        {
                          isSelectedCardPicker &&
                          (
                            <CreditCardPicker
                              validCreditCards={validCreditCards}
                              defaultCardId={selectedCardPaymentId || validCreditCards[0].id}
                              onSelected={(paymentId) => creditCardsPickerHandler(paymentId)}
                              {...passwordValidation}
                            />
                          )
                        }
                      </div>
                      <div className={classNames(voucherStyles.border, voucherStyles.paymentContainer)}>
                        <div
                          className={voucherStyles.selector}
                          onClick={() => setIsSelectedCardPicker(false)}
                        >
                          <DotIndicator
                            isSelected={!isSelectedCardPicker}
                          />
                          Use a different card
                        </div>
                        {!isSelectedCardPicker &&
                        (
                          <div className={styles.iframeWrapper}>
                            <EvIframe
                              src={iframe}
                              onLoad={onIframeLoad}
                              onCancel={onCancel}
                            />
                            {
                              continueCta && (
                                <div className={styles.cta} onClick={onClickContinueCta}>
                                  <p>{continueCta}</p>
                                </div>
                              )
                            }
                          </div>
                        )}
                      </div>
                    </>
                  )}
              </>
            )
            : (
              <div className={styles.iframeWrapper}>
                <EvIframe
                  src={iframe}
                  onLoad={onIframeLoad}
                  onCancel={onCancel}
                />
                {
                  continueCta && (
                    <div className={styles.cta} onClick={onClickContinueCta}>
                      <p>{continueCta}</p>
                    </div>
                  )
                }
              </div>
            )
        }
      </div>
    </div>
  )
}

SubscriptionPaymentView.propTypes = {
  iframe: PropTypes.string.isRequired,
  onIframeLoad: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  continueCta: PropTypes.string,
  onClickContinueCta: PropTypes.func.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  isVoucherApplied: PropTypes.bool.isRequired,
  details: PropTypes.arrayOf(propType(SUBSCRIPTION_PAYMENT_VIEW_DETAILS_FRAGMENT)),
  validCreditCards: PropTypes.arrayOf(CreditCardType).isRequired,
  onCompletePayment: PropTypes.func.isRequired,
  subscriptionLoading: PropTypes.bool.isRequired,
  isSelectedCardPicker: PropTypes.bool.isRequired,
  setIsSelectedCardPicker: PropTypes.func.isRequired,
  selectedCardPaymentId: PropTypes.string,
  setSelectedCardPaymentId: PropTypes.func.isRequired,
  setSubscriptionLoading: PropTypes.func.isRequired
}

SubscriptionPaymentView.defaultProps = {
  continueCta: null
}

export default SubscriptionPaymentView

import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
import { useLazyQuery } from '@apollo/react-hooks'
import { path } from 'ramda'
import { useDispatch } from 'react-redux'

import { modalContent } from '../../../shared/subscription-constants'
import VOUCHER_WITH_SKU_QUERY from '../../../../../graphql/queries/voucher-with-sku.gql'
import { getGQLErrorMsg, getGQLErrorCode, getGQLErrorDetails } from '../../../../lib/apollo'
import { MW_ERRORS } from '../../../../constants'
import {
  setVoucherValidateError,
  setVoucherValidateType
} from '../../../../actions'
import { usePlanDetails } from '../../../shared/hooks/use-plan-details'
import { useStyles } from './subscription-voucher-modal.styles'

const SubscriptionVoucherModal = () => {
  const styles = useStyles()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [voucher, setVoucher] = useState('')
  const { getPlanTitleBySku, getPlanSkuByType } = usePlanDetails()

  useEffect(() => {
    dispatch(setVoucherValidateError(''))
    dispatch(setVoucherValidateType(''))
  }, [])

  const [validateVoucher] = useLazyQuery(VOUCHER_WITH_SKU_QUERY, {
    onCompleted: result => {
      setSubmitting(false)
      const voucherType = path(['voucherWithSku', 'type'], result)
      if (voucherType !== 'SVOD') {
        setError('Only subscription vouchers are accepted')
      } else {
        history.push({
          ...location,
          search: qs.stringify({
            ...qs.parse(location.search),
            content: getNextContent(),
            voucher
          })
        })
      }
    },
    onError: errorResult => {
      setSubmitting(false)

      const errorMsg = getGQLErrorMsg(errorResult)
      const errorCode = getGQLErrorCode(errorResult)
      const errorDetails = getGQLErrorDetails(errorResult)

      setError(errorMsg)

      if (errorCode === MW_ERRORS.VOUCHER_NOT_APPLICABLE) {
        dispatch(setVoucherValidateError(errorMsg))
        dispatch(setVoucherValidateType(getPlanTitleBySku(errorDetails.voucherSku)))

        history.push({
          ...location,
          search: qs.stringify({
            ...qs.parse(location.search),
            content: modalContent.voucherValidateErrorAfterLogin
          })
        })
      }
    }
  })

  const addVoucherHandler = () => {
    setSubmitting(true)
    const { plan } = qs.parse(location.search)
    validateVoucher({
      variables: {
        voucherCode: voucher,
        signupSku: getPlanSkuByType(plan)
      }
    })
  }

  /**
   * Checking user has credit card status to get next content params
   */
  const getNextContent = () => {
    return modalContent.payment
  }

  /**
   * Handle skip button
   */
  const skipVoucherHandler = () => {
    history.push({
      ...location,
      search: qs.stringify({
        ...qs.parse(location.search),
        content: getNextContent()
      })
    })
  }

  return (
    <div className={styles.voucherContainer}>
      <div className={styles.title}>
        Do you have a voucher code?
        <br />
        Enter it here.
      </div>
      <div className={styles.inputContainer}>
        <label className={styles.label}>
          <input
            value={voucher}
            name="voucherCode"
            type="text"
            disabled={submitting}
            onChange={event => setVoucher(event.target.value)}
            className={classNames(styles.input, {
              [styles.inputError]: Boolean(error)
            })}
          />
        </label>

        <p
          className={classNames(styles.fieldError, {
            [styles.visible]: Boolean(error)
          })}
        >
          {error}
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <button
          disabled={submitting}
          onClick={addVoucherHandler}
          className={classNames(styles.primaryButton, styles.buttons)}
        >
          Redeem
        </button>
        <button
          onClick={skipVoucherHandler}
          className={classNames(styles.secondaryButton, styles.buttons)}
        >
          Skip
        </button>
      </div>
    </div>
  )
}

export default SubscriptionVoucherModal

import { Formik } from 'formik'
import { pathEq } from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers, withProps } from 'recompose'

import Yup from '../../lib/yup'
import { getGQLErrorMsg, getGQLErrorCode } from '../../lib/apollo'
import { getModalLocation } from '../../lib/modal'

import PinForgottenForm from '../../components/pin/request-form'

import withApolloQueryAndLoading from '../../hoc/with-apollo-query-and-loading'
import { FORM_MESSAGES, MODALS } from '../../constants'

import VALIDATE_PASSWORD_QUERY from '../../../graphql/queries/validate-password.gql'

const enhance = compose(
  withRouter,
  withApolloQueryAndLoading, // handle loading screen
  connect(
    () => ({}),
    dispatch => ({
      dispatch
    })
  ),
  withProps(({ location }) => ({
    passwordForgottenLocation: getModalLocation(
      location,
      MODALS.qsParams.passwordForgotten
    )
  })),
  withHandlers({
    onCancel: ({ history, location }) => () => {
      history.push(location.pathname)
    }
  }),
  Formik({
    // Define the form's validation schema with Yup.
    validationSchema: Yup.object().shape({
      password: Yup.string().required(FORM_MESSAGES.required)
    }),

    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: () => ({
      password: ''
    }),

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (values, { props, setErrors, setSubmitting }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setErrors({ password: '' })
      const { apolloQuery } = props

      apolloQuery('pinPasswordValidation', {
        query: VALIDATE_PASSWORD_QUERY,
        variables: values
      }, error => pathEq(['data', 'validatePassword'], false, error))
        .then((res) => {
          setSubmitting(false)
          if (pathEq(['data', 'validatePassword'], true, res)) {
            props.onRequestSuccess(values.password)
          }
        })
        .catch((error) => {
          // display error
          setSubmitting(false)
          if (getGQLErrorCode(error) === 'BAD_PASSWORD') {
            setErrors({ password: 'Incorrect password' })
            return
          }

          setErrors({ password: getGQLErrorMsg(error) })
        })
    }
  })
)

export default enhance(PinForgottenForm)

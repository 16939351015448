import React from 'react'
import PropTypes from 'prop-types'

import NeonButton from './neon-form-elements/button'
import NeonPasswordInput from '../forms/password-input'
import styles from './request-form.css'

function PasswordForgottenForm({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  error,
  touched,
  isSubmitting
}) {
  const isSubmittingClass = isSubmitting ? styles.isSubmitting : ''

  return (
    <form className={`${styles.form} ${isSubmittingClass}`} onSubmit={handleSubmit}>
      <div className={styles.formRow}>
        <div className={styles.formRowItem}>
          <NeonPasswordInput
            label="Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            warning={(values.password || touched.password) && errors.password}
            isValid={!error && Boolean(values.password && touched.password && !errors.password)}
            isInvalid={error}
            darkTheme
          />
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.formRowItem}>
          <NeonPasswordInput
            label="Confirm password"
            name="passwordConfirmation"
            value={values.passwordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            warning={(values.passwordConfirmation || touched.passwordConfirmation) &&
              errors.passwordConfirmation}
            isValid={!error && Boolean(
              values.passwordConfirmation &&
                touched.passwordConfirmation &&
                !errors.passwordConfirmation
            )}
            isInvalid={error}
            darkTheme
          />
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.saveWrapper}>
          <NeonButton disabled={isSubmitting} text="Reset" />
        </div>
      </div>
      <div className={styles.errorWrapper}>
        <p className={styles.errorMsg}>{error}</p>
      </div>
    </form>
  )
}

PasswordForgottenForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

PasswordForgottenForm.defaultProps = {
  error: ''
}

export default PasswordForgottenForm
